import { Injectable, OnDestroy, inject } from '@angular/core';
import {
  BehaviorSubject,
  Subject,
  Subscription,
  finalize,
  takeUntil,
} from 'rxjs';
import {
  DownloadFileDetails,
  XLSX_FILE_DOWNLOAD_DETAILS,
} from '@qts-sdp-ui/shared-data';
import * as saveAs from 'file-saver';
import { ApiService, HelperService } from '@qts-sdp-ui/shared-util';

@Injectable({
  providedIn: 'root',
})
export class DownloadService implements OnDestroy {
  private subscriptionHolder: Subscription = new Subscription();
  private apiService = inject(ApiService);
  private helperService = inject(HelperService);
  private isDownloadingSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isDownloading$ = this.isDownloadingSubject.asObservable();

  // Subject
  private destroy$ = new Subject<void>();

  //#region LifeCycle
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.subscriptionHolder.unsubscribe();
  }
  //#endregion

  public downloadBlob(
    requestUrl: string,
    fileName: string,
    isSensorReport = false,
    downloadFileDetails?: DownloadFileDetails
  ) {
    this.isDownloadingSubject.next(true);
    this.subscriptionHolder.add(
      this.apiService
        .download(requestUrl, {
          responseType: 'blob',
        })
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => this.isDownloadingSubject.next(false))
        )
        .subscribe((res) => {
          this.saveFile(
            res,
            {
              fileName,
              ...(downloadFileDetails ?? XLSX_FILE_DOWNLOAD_DETAILS),
            },
            isSensorReport
          );
        })
    );
  }
  //#region private
  private saveFile(
    res: any,
    saveDetails: DownloadFileDetails,
    isSensorReport = false
  ) {
    let fileName: string;
    if (!isSensorReport) {
      fileName = this.helperService.getDownloadFileName(saveDetails.fileName);
    } else {
      fileName = saveDetails.fileName as any;
    }
    const blobData: Blob = new Blob([res], {
      type: saveDetails.fileType,
    });
    saveAs(blobData, fileName + saveDetails.fileExtension);
  }
  //#endregion
}
