import { Injectable } from '@angular/core';
import { GenericType, Option, TimelineStep } from '@qts-sdp-ui/shared-data';
import {
  DATE_FORMATS,
  DateTimeService,
  HttpService,
} from '@qts-sdp-ui/shared-util';
import { QosCommonService } from '../../../qos-common-service';
import { URL_NAMES } from '../../../qos-common-url';
import { manageMaintenanceActionHistory } from '../../../qos-common.model';
import { map } from 'rxjs';
import { ISUBMITMAINTENANCE } from '../../model/manage-maintenance-submit.model';

@Injectable({
  providedIn: 'root',
})
export class ManageCorrectiveMaintenanceService {
  constructor(
    private dateTimeService: DateTimeService,
    private http: HttpService,
    private qosCommonService: QosCommonService
  ) {}

  public getDecorateHistoryData(
    historyData?: manageMaintenanceActionHistory[]
  ) {
    const createdHistory: Array<TimelineStep> = [];

    if (historyData) {
      createdHistory.push(
        ...historyData.map(
          (history) =>
            ({
              status: history.action ?? '',
              timestamp: this.dateTimeService.formate(
                history.operationDate ?? '',
                DATE_FORMATS.M_D_YYYY_HH_MM
              ),
              description: `${history.author}: ${history.comment ?? ''}`,
            } as TimelineStep)
        )
      );
    }

    return [...createdHistory];
  }

  public getServiceProvider() {
    return this.http
      .get<any>(this.qosCommonService.getApiUrl(URL_NAMES.GET_SERVICE_PROVIDER))
      .pipe(
        map((res: any[]) => ({
          data: res.map(
            (response) =>
              ({
                name: response.name,
                code: response.sysId,
              } as Option)
          ),
        }))
      );
  }

  public updateManageCorrectiveMaintenance(
    id: number,
    maintenancePayload: ISUBMITMAINTENANCE
  ) {
    return this.http.put(
      this.qosCommonService.updateCorrectiveMaintenance + id,
      maintenancePayload
    );
  }

  public addMopDocuments(
    sysId: string,
    payload: GenericType,
    header?: GenericType
  ) {
    const Url = this.qosCommonService.correctiveUpload + sysId + '/upload-mops';
    return this.http.postCase(Url, payload);
  }
  public addWorkOrderDocuments(
    sysId: string,
    payload: GenericType,
    header?: GenericType
  ) {
    const Url =
      this.qosCommonService.correctiveUpload + sysId + '/upload-attachments';
    return this.http.postCase(Url, payload);
  }
  public fetchCorrectiveChangeNumber(woSysId: string) {
    return this.http.get<any>(
      this.qosCommonService.fetchCorrectiveChangeNumber + '?woSysId=' + woSysId
    );
  }
}
