import { Status } from '@qts-sdp-ui/shared-data';

export enum FILTER_CONFIG_KEY {
  deviceNames = 'deviceNames', //company
  statuses = 'statuses',
  assignedTo = 'assignedTo',
  assignedGroup = 'assignedGroups',
  workLocations = 'workLocation',
  initialReason = 'initialReason',
  reportedBy = 'reportedBy',
  workOrderType = 'workOrderType',
  dateFrom = 'updatedDateFrom',
  dateTo = 'updatedDateTo',
  buildings = 'buildings',
  campuses = 'campuses',
  parentNumber = 'parentNumber',
  priorities = 'priorities',
  serviceProviders = 'serviceProviders',
  externalTechnicians = 'externalTechnician',
  capital = 'capital',
}

export const FILTER_LABELS = {
  [FILTER_CONFIG_KEY.deviceNames]: 'Device Name',
  [FILTER_CONFIG_KEY.statuses]: 'State',
  [FILTER_CONFIG_KEY.assignedTo]: 'Assigned To',
  [FILTER_CONFIG_KEY.assignedGroup]: 'Assignment Group',
  [FILTER_CONFIG_KEY.workLocations]: 'Location',
  [FILTER_CONFIG_KEY.initialReason]: 'Initial Reason',
  [FILTER_CONFIG_KEY.reportedBy]: 'Reported By',
  [FILTER_CONFIG_KEY.workOrderType]: 'Work Order Type',
  updatedDate: 'Updated Date',
  from: 'From',
  to: 'To',
  [FILTER_CONFIG_KEY.campuses]: 'Campus',
  [FILTER_CONFIG_KEY.buildings]: 'Building',
  [FILTER_CONFIG_KEY.parentNumber]: 'Parent Work Order',
  [FILTER_CONFIG_KEY.priorities]: 'Priority',
  [FILTER_CONFIG_KEY.serviceProviders]: 'Service Provider',
  [FILTER_CONFIG_KEY.externalTechnicians]: 'External Technician',
  [FILTER_CONFIG_KEY.capital]: 'Capital',
};

export const DEFAULT_FILTER = [];

export const NON_SORTABLE: string[] = [];

export interface MaintenanceListData {
  id: number;
  sysId: string;
  number: string;
  name: string;
  state: string;
  buildingName: string;
  priority: string;
  workOrderType: string;
  assetName: string;
  description: string;
  campusName: string;
  assignedToUsername: string;
  assignedToName: string;
  resolutionCode: string;
  assignmentGroup: string;
  currentAssetLocation: string;
  workLocation: string;
  serviceProvider: string;
  externalTechnician: string;
  reportedBy: string;
  updatedDate: string;
  parentWorkOrderNumber: string;
  serviceProviderName: string;
  capital: string;
  changeRequestNumber: string;
  updatedOn: string;
}

export interface MaintenanceTable {
  id: number;
  sysId: string;
  number: string;
  name: string;
  state: string;
  buildingName: string;
  priority: string;
  workOrderType: string;
  assetName: string;
  description: string;
  campusName: string;
  assignedToUsername: string;
  assignedToName: string;
  resolutionCode: string;
  assignmentGroup: string;
  currentAssetLocation: string;
  workLocation: string;
  serviceProvider: string;
  externalTechnician: string;
  reportedBy: string;
  updatedDate: string;
  parentWorkOrderNumber: string;
  serviceProviderName: string;
  capital: string;
  changeRequestNumber: string;
  updatedOn: string;
  status: Status;
  type: Status;
}
