import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { casesListColumns } from './work-order-details.mock/work-order-details-list-columns.mock';
import { Column, SearchListState, Response } from '@qts-sdp-ui/shared-data';
import { HttpService } from '@qts-sdp-ui/shared-util';
import { QosCommonService, URL_NAMES } from '@qts-qos-ui/qos-app-data';
import { IWorkOrder } from '../../model/work-order-model';

@Injectable({
  providedIn: 'root',
})
export class WorkOrderDetailsService {
  constructor(
    private http: HttpService,
    private qosCommonService: QosCommonService
  ) {}

  fetchColumns(): Observable<Column[]> {
    return of(casesListColumns as Column[]);
  }

  // getPageInfo() {
  //   return casesList.pageInfo;
  // }

  /**
   * Helper function to map api response properties to UI table columns
   * @param list
   * @returns CrossConnectsTable[]
   */

  public decorateWorkOrderListData(list: any[]) {
    return list.map((workOrder: any) => ({
      number: workOrder.number,
      deviceNames: workOrder.assetName,
      status: {
        label: workOrder.state,
        code: workOrder.state,
      },
      scheduledStartTimeFormated:
        this.qosCommonService.convertDateFormatForRequest(
          workOrder.scheduledStartTime,
          'MM-DD-YYYY'
        ),
      assignedToName: workOrder.assignedToName,
      ...workOrder,
    }));
  }

  public getWorkOrderDetailsList(
    input: SearchListState,
    assignedToValue: string,
    timeZone: string,
    workOrderType: string,
    targetViewType: string,
    buildings: any
  ): Observable<Response<any>> {
    const searchValue = input.search
      ? `&query=${encodeURIComponent(input.search.trim())}`
      : '';
    const assignedTo = assignedToValue ? `&assignedTo=${assignedToValue}` : '';

    const url =
      this.qosCommonService.correctiveWorkOrder +
      `?pageNumber=${input.page}&pageSize=${input.size}&sort=${input.column}.${input.sort}${searchValue}${assignedTo}` +
      '&timeZone=' +
       timeZone
       +
      '&workOrderType=' +
      workOrderType +
      '&targetViewType=' +
      targetViewType +
      '&buildings=' +
      buildings;
    return this.http.get<IWorkOrder>(url);
  }

  public getWorkOrderDetailsById(id: number): Observable<any> {
    return this.http.get<any>(
      this.qosCommonService.getApiUrl(URL_NAMES.GET_WORK_ORDER_DETAILS_BY_ID) +
        id
    );
  }
}
