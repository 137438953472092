import { FormDetailsViewType, Option } from '@qts-sdp-ui/shared-data';
import {
  FormDetailsViewColumnKey,
  ITimeCardList,
  SupportCaseType,
} from './qos-common.model';

export const resolutionCodeData: Option[] = [
  { name: 'Maintenance Completed - No Issues', code: 'MAINTENANCE_COMPLETED' },
  { name: 'Vendor Repaired', code: 'VENDOR_REPAIRED' },
  { name: 'QTS Repaired', code: 'QTS_REPAIRED' },
  { name: 'Parts Replacement', code: 'PART_REPLACEMENT' },
  { name: 'Unresolved', code: 'UNRESOLVED' },
  { name: 'Issue No longer Exists', code: 'ISSUE_NO_LONGER_EXISTS' },
  { name: 'Device in Use', code: 'DEVICE_IN_USE' },
  { name: 'Unable to Locate Device', code: 'UNABLE_TO_LOCATE_DEVICE' },
  { name: 'User Education', code: 'USER_EDUCATION' },
];

export const stateData: Option[] = [
  { name: 'Closed Complete', code: 'CLOSED_COMPLETE' },
  { name: 'Closed Cancelled', code: 'CLOSED_CANCELLED' },
  { name: 'Work In Progress', code: 'WORK_IN_PROGRESS' },
  { name: 'Pending Review', code: 'PENDING_REVIEW' },
  { name: 'On Hold', code: 'ON_HOLD' },
  { name: 'Planning', code: 'PLANNING' },
  { name: 'Draft', code: 'DRAFT' },
];
export const notificationTypes: Option[] = [
  { name: 'Customer Only', code: 'Customer_Only' },
  { name: 'Employee and Customer', code: 'Employee_and_Customer' },
  { name: 'Employee Only', code: 'Employee_Only' },
];
export const closeCodes: Option[] = [
  { name: 'Successful', code: 'Successful' },
  { name: 'Successful with Issues', code: 'Successful_with_Issues' },
  { name: 'Unsuccessful', code: 'Unsuccessful' },
  { name: 'Cancelled', code: 'Cancelled' },
];
export const timeCardTableData: ITimeCardList[] = [
  { day: 'Sunday', tracking: '00:00' },
  { day: 'Monday', tracking: '00:00' },
  { day: 'Tuesday', tracking: '00:00' },
  { day: 'Wednesday', tracking: '00:00' },
  { day: 'Thursday', tracking: '00:00' },
  { day: 'Friday', tracking: '00:00' },
  { day: 'Saturday', tracking: '00:00' },
  { day: "This Week's Time", tracking: '00:00' },
];

export const TOAST_LABELS = {
  successUpdateMaintenance: 'Maintenance Updates Processing',
  successCorrectiveMaintenance: 'Maintenance Updates Processing',
  remoteHandUpdate: 'Remote Hands Updated Successfully',
  successUpdateManageCorrectiveMaintenance:
    'Corrective Maintenance Updates Processing',
};
export const initiationReasonData: Option[] = [
  {
    name: 'Identified During Rounds/Inspection',
    code: 'IDENTIFIED_DURING_ROUNDS ',
  },
  {
    name: 'Identified during Preventative Maintenance',
    code: 'IDENTIFIED_DURING_PREVENTATIVE_MAINTENANCE',
  },
  { name: 'EPMS / BMS Alarm', code: 'EPMS_BMS_ALARM' },
  { name: 'Incident Occurrence', code: 'INCIDENT_OCCURRENCE' },
  { name: 'Customer Reported', code: 'CUSTOMER_REPORTED' },
];

export const initiationReasonDataForm: Option[] = [
  {
    name: 'Identified During Rounds/Inspection',
    code: 'IDENTIFIED_DURING_ROUNDS ',
  },
  {
    name: 'Identified during Preventative Maintenance',
    code: 'IDENTIFIED_DURING_PREVENTIVE_MAINTENANCE',
  },
  { name: 'EPMS / BMS Alarm', code: 'EPMS_BMS_ALARM' },
  { name: 'Incident Occurrence', code: 'INCIDENT_OCCURRENCE' },
  { name: 'Customer Reported', code: 'CUSTOMER_REPORTED' },
];
export const priorityData: Option[] = [
  { name: '1 - Critical', code: 'CRITICAL' },
  { name: '2 - High', code: 'HIGH' },
  { name: '3 - Moderate', code: 'MODERATE' },
  { name: '4 - Low', code: 'LOW' },
];

export const COT_READ_ONLY_USER = 'COT_READ_ONLY_USER';

export const DAYS_FOR_DUE_DATE = 30;
export const PREVENTATIVE_MAINTENANCE_TYPE = 'PREVENTATIVE_MAINTENANCE';
export const CORRECTIVE_MAINTENANCE_TYPE = 'CORRECTIVE_MAINTENANCE';

export const workOrderType: Option[] = [
  { name: 'Preventative Maintenance', code: 'PREVENTIVE_MAINTENANCE' },
  { name: 'Corrective Maintenance', code: 'CORRECTIVE_MAINTENANCE' },
];
export const capital: Option[] = [
  { name: 'Yes', code: 'true' },
  { name: 'No', code: 'false' },
];

export const rhType: Option[] = [
  { name: 'Audit', code: 'AUDIT' },
  { name: 'Cable Add', code: 'CABLE_ADD' },
  { name: 'Client Hardware Removal', code: 'CLIENT_HARDWARE_REMOVAL' },
  { name: 'Rack and Stack', code: 'RACK_AND_STACK' },
  { name: 'Tape Rotation', code: 'TAPE_ROTATION' },
  { name: 'Troubleshooting', code: 'TROUBLESHOOTING' },
  { name: 'Loading/Unloading equipment', code: 'LOADING_UNLOADING_EQUIPMENT' },
  { name: 'Facilities Housekeeping', code: 'FACILITIES_HOUSEKEEPING' },
];

export const rhStateData: Option[] = [
  { name: 'Pending', code: 'PENDING' },
  { name: 'Open', code: 'OPEN' },
  { name: 'Work In Progress', code: 'WORK_IN_PROGRESS' },
  { name: 'Closed Complete', code: 'CLOSED_COMPLETE' },
  { name: 'Closed Incomplete', code: 'CLOSED_INCOMPLETE' },
  { name: 'Closed Skipped', code: 'CLOSED_SKIPPED' },
];

export const approvalData: Option[] = [
  { name: 'Approved', code: 'Approved' },
  { name: 'Rejected', code: 'Rejected' },
];

export const laborAddedData: Option[] = [
  { name: 'Not Applicable', code: 'NOT_APPLICABLE' },
  { name: 'Present', code: 'PRESENT' },
];

export const FILE_UPLOAD = {
  shortDescriptionCharacterCount: 20,
  maxFileSize: 10485760, // 10MB
  allowedFileTypes:
    '.XLS, .XLSX, .DOC, .DOCX, .PPT, .PPTX, .PDF, .JPG, .PNG, .BMP .TXT',
  allowedFileTypesDescription:
    '.XLS, .XLSX, .DOC, .DOCX, .PPT, .PPTX, .PDF, .JPG, .PNG, .BMP or .TXT, 10 MB limit',
  allowedFileTypesLabel:
    'Allowed file types: .XLS, .XLSX, .DOC, .DOCX, .PPT, .PPTX, .PDF, .JPG, .PNG, .BMP .TXT',
  fileAllowed:
    'Only xls, xlsx, doc, docx, ppt, pptx, pdf, jpg, png, bmp and txt file type upload is allowed.',
  fileExeededMaximumSize: 'Uploaded file exceeded maximum allowed file size ',
  byteToMB: 1048576,
  allowedFileTypesText: 'Allowed File Types: ',
  isRequired: 'This field is required',
  fileExceededMaximumSize: 'Uploaded file exceeded maximum allowed file size ',
};

export const DETAILS_VIEW_REMOTE_HANDS_CONFIG: Array<FormDetailsViewType> = [
  {
    label: 'Company',
    description: '',
    itemKey: FormDetailsViewColumnKey.companyName,
    isRequest: true,
  },
  {
    label: 'Approval',
    description: '',
    itemKey: FormDetailsViewColumnKey.approval,
    isRequest: true,
  },
  {
    label: 'Priority',
    description: '',
    itemKey: FormDetailsViewColumnKey.priority,
    isRequest: true,
  },
  {
    label: 'State',
    description: '',
    itemKey: FormDetailsViewColumnKey.state,
    isRequest: true,
  },
  {
    label: 'Assigned to',
    description: '',
    itemKey: FormDetailsViewColumnKey.assignee,
    isRequest: true,
  },
  
  {
    label: 'RH Type',
    description: '',
    itemKey: FormDetailsViewColumnKey.rhType,
    isRequest: true,
  },
  {
    label: 'Short Description',
    description: '',
    itemKey: FormDetailsViewColumnKey.shortDescription,
    isRequest: true,
  },
  {
    label: 'Location',
    description: '',
    itemKey: FormDetailsViewColumnKey.location,
    isRequest: true,
  },
  {
    label: 'Building',
    description: '',
    itemKey: FormDetailsViewColumnKey.buildingName,
    isRequest: true,
  },
  {
    label: 'Space or Rack',
    description: '',
    itemKey: FormDetailsViewColumnKey.spaceOrRack,
    isRequest: true,
  },
  {
    label: 'Description',
    description: '',
    itemKey: FormDetailsViewColumnKey.description,
    isRequest: true,
  },
];

// Keep at last
export const FORM_DETAILS_VIEW_CONFIG: Record<
  SupportCaseType,
  Array<FormDetailsViewType>
> = {
  [SupportCaseType.REMOTE_HANDS]: DETAILS_VIEW_REMOTE_HANDS_CONFIG,
};

export const MY_ACTION = 'MY_ACTION';

export const Closed_Complete_Name = 'CLOSED COMPLETE';
export const Closed_Cancelled_Name = 'CLOSED CANCELLED';
export const On_Hold_Name = 'ON HOLD';

export const DEFAULT_ITEMS_PAGE_PREVENTIVE_WORK_ORDER = 10000;

export const WEEK='WEEK'
