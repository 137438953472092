import { Injectable, inject } from '@angular/core';
import { of, shareReplay, Observable } from 'rxjs';
import { Column, GenericType, Option, Response } from '@qts-sdp-ui/shared-data';
import {
  DateTimeService,
  HttpService,
  toOption,
} from '@qts-sdp-ui/shared-util';
import { remoteHandsListColumns } from './features.mock/remote-hands.list-columns.mock';
import { formatDate } from '@angular/common';
import { QosCommonService } from './qos-common-service';
import {
  ActionSummaryResponse,
  DayOrderCount,
  Escort,
  RoundResponse,
} from './qos-common.model';
import { buildingData, roundData } from './features.mock/rounds.mock';

export interface ICalenderFilter {
  period?: string;
  startDate?: string;
  endDate?: string;
  campus?: [];
  building?: [];
  assetType?: [];
  assignedTo?: string;
}

@Injectable({
  providedIn: 'root',
})
export class DashboardApiService {
  private qosUrlService = inject(QosCommonService);
  public httpService = inject(HttpService);

  public dashboardSelectedBuilding: Option[] | null = null;

  fetchRemoteHandsColumns(): Observable<Column[]> {
    return of(remoteHandsListColumns as Column[]);
  }

  public getBuildings() {
    return this.httpService
      .get<any>(this.qosUrlService.buildings)
      //return of(buildingData)
      .pipe(shareReplay(), toOption({ codeFieldName: 'name' }));
  }

  public getWeeklyCalendar(
    startDate: string,
    building: string[],
    assignedTo: string
  ) {
    const params = `?startDate=${startDate}&building=${building.join(',')}${
      assignedTo ? '&assignedTo=' + assignedTo : ''
    }`;
    return this.httpService
      .get<any>(this.qosUrlService.weeklyCalendar + params)
      .pipe(shareReplay());
  }

  public getMyAction(building: string) {
    const params = `?user_timezone=${DateTimeService.getLocalTimeZone()}&building=${building}`;

    return this.httpService
      .get<DayOrderCount[]>(`${this.qosUrlService.myAction}${params}`)
      .pipe(shareReplay());
  }

  public getMyTeamAction(building: string) {
    const params = `?user_timezone=${DateTimeService.getLocalTimeZone()}&building=${building}`;

    return this.httpService
      .get<DayOrderCount[]>(`${this.qosUrlService.myTeamAction}${params}`)
      .pipe(shareReplay());
  }

  public getActionSummary(building: string[]) {
    const params = `?user_timezone=${DateTimeService.getLocalTimeZone()}&building=${building.join(
      ','
    )}`;

    return this.httpService
      .get<ActionSummaryResponse[]>(
        `${this.qosUrlService.actionSummary}${params}`
      )
      .pipe(shareReplay());
  }

  getMyRemoteHandTickets(buildings: string[]) {
    return this.httpService
      .get<ActionSummaryResponse[]>(
        `${this.qosUrlService.myRemoteHandTickets}?building=${buildings.join(
          ','
        )}`
      )
      .pipe(shareReplay());
  }

  getEscorts(buildings: string[], date = new Date()) {
    const escortDate = formatDate(date, 'yyyy-MM-dd', 'en-US');
    return this.httpService
      .get<Response<Escort>>(
        `${this.qosUrlService.escort}?building=${buildings.join(
          ','
        )}&pageNumber=1&pageSize=10000&escortDate=${escortDate}`
      )
      .pipe(shareReplay());
  }

  updateEscort(caseNumber: string, body: GenericType) {
    return this.httpService.put(
      `${this.qosUrlService.updateEscort}${caseNumber}`,
      body
    );
  }

  updateRound(sysId: string, body: GenericType) {
    return this.httpService.put(
      `${this.qosUrlService.updateRound}${sysId}`,
      body
    );
  }

  getRounds(buildings: string[], date = new Date()) {
    const roundDate = this.qosUrlService.formattedDateWithTimezoneOffset(date); // formatDate(date, 'yyyy-MM-dd', 'en-US');
    return this.httpService
      .get<RoundResponse[]>(
        `${this.qosUrlService.rounds}?building=${buildings.join(
          ','
        )}&pageNumber=1&pageSize=10000&roundDate=${encodeURIComponent(
          roundDate
        )}`
      )
    //return of(roundData)
      .pipe(shareReplay());
  }
}
