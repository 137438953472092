<lib-qts-qos-layout
  [showOptions]="isAuthenticated"
  [appTitle]="title"
  [nav]="nav"
  [isTimerRunning]="isTimerRunning"
  (timerClick)="toggleStopWatch()"
  (logOutFunction)="logOutFunction()"
  (plusIconClick)="plusIconClick()">
  <router-outlet></router-outlet>
</lib-qts-qos-layout>

<lib-qts-stop-watch
  *ngIf="showStopWatch"
  [cotReadOnlyUser]="cotReadOnlyUser"
  [open]="true"
  [timeLogEntriesList]="timeLogEntriesList"
  [startedTimeCard]="startedTimeCard"
  [stopWatchLabels]="stopWatchLabels"
  [isTimeLogLoading]="isTimeLogLoading"
  [allTimeCardState]="allTimeCardState"
  [isTimerRunning]="isTimerRunningUpdateTimer"
  [timer]="timer"
  (closeTimeLogModel)="closeTimeLogModel()"
  [isLoadingSearch]="isLoadingSearch"
  [searchResultTimeLog]="searchResultTimeLog"
  [userInfo]="userInfo"
  (filterOptionEmitter)="filterOptionEmitter($event)"
  (selectedValueForAddTimeLog)="selectedValueForAddTimeLog($event)"
  (updateFilterState)="updateFilterState($event)"
  (stopStartStopWatch)="stopStartStopWatch($event)"
  (deleteTimeLogEntry)="deleteTimeLogEntry($event)" />
