import { Breadcrumb } from '@qts-sdp-ui/shared-data';

export const QOS_TEXT = {
  MANAGE_MAINTENANCE_FORM: {
    maintenanceCalendar: 'Maintenance Calendar',
    manageMaintenance: 'Manage Preventive Maintenance',
    workOrderDetails: 'Work Order Details',
    updateWorkOrder: 'Update Work Order Details',
    tableSearchPlaceholder: 'Search by work order, device name or state',
    resolutionCode: 'Resolution Code',
    select: 'Select',
    isRequired: ' is required',
    state: 'State',
    name: 'Name',
    assignedToMe: 'Assign Selected WOs to Me',
    comments: 'Comments',
    documentation: 'Documentation',
    serviceProvider: 'Service Provider',
    externalTechnician: 'External Technician',
    nameOfTech: 'Name of Tech',

    commentHistory: 'Comment History',

    changeRecordDetails: 'Change Record Details',
    updateChangeRecord: 'Update Change Record',
    notificationType: 'Notification Type',
    ciSearch: 'CI Search',
    notifyAllCustomersInBuilding: 'Notify all customers in building',
    closeCode: 'Close Code',
    closeNotes: 'Close Notes',
    completedMOP: 'Completed MOP',
    postImplementationReview: 'Post-Implementation Review',

    timeCard: 'Time Card',
    updateTimeCard: 'Update Time Card',
    timeLog: 'Time Log',
    timeLogTitle: 'Time Log (HH:MM)',
    enterTimeLog: 'Enter time log',
    timeLogError: 'Time Log is required and please enter in HH:MM format.',

    submit: 'Submit',
    cancel: 'Cancel',
    save: 'Save',
    close: 'Close',
    startDate: 'Start Date',
    window: 'Window',
  },

  MAINTENANCE_CALENDAR: {
    maintenanceCalendar: 'Maintenance Calendar',
  },

  CHANGE_REQUEST_LABELS: {
    filterLabel: 'Filter',
    searchPlaceHolder: 'Search for change number, device name',
    emptyMessage: 'No records were found.',
    BREADCRUMBS: {
      orders: 'Orders',
      newPc: 'New Power Circuit Port',
      pcOrder: 'Power Circuit Order',
      editPcOrder: 'Edit Power Circuit Order',
    },
  },
  MAINTENANCE_LIST_LABELS: {
    filterLabel: 'Filter',
    searchPlaceHolder:
      'Search for work order number, device name, assigned to, location, service provider, external technician, order summary, order description, change request...',
    emptyMessage: 'No records were found.',
  },
  REMOTE_HAND_LIST_LABELS: {
    filterLabel: 'Filter',
    searchPlaceHolder:
      'Search for number, assignee, requester, rh type, company, short description, created by, updated by...',
    emptyMessage: 'No records were found.',
  },
  CORRECTIVE_MAINTENANCE_FORM: {
    createCorrectiveMaintenanceWorkOrder:
      'Create Corrective Maintenance Work Order',
    submit: 'Submit',
    documentation: 'Documentation',
    cancel: 'Cancel',
    select: 'Select',
    isRequired: ' is required',
    asset: 'Asset',
    initiationReason: 'Initiation Reason',
    dueDate: 'Due Date',
    reportedBy: 'Reported By',
    workProject: 'Work Project',
    summary: 'Summary',
    description: 'Description',
    priority: 'Priority',
    assignee: 'Assignee',
    capital: 'Capital',
    selectCodeDefault: 'Select Assignee',
  },
  DASHBOARD: {
    todayAction: "Today's Actions",
    preventiveMaintenance: 'Preventive Maintenance',
    correctiveMaintenance: 'Corrective Maintenance',
    remoteHands: 'Remote Hands',
    myAction: 'My Actions',
    teamAction: 'Team Actions',
    searchPlaceholder: 'Search for Work Order',

    escortHeader: 'Escort & Stays',
    escort: 'Escorts',
    checkInWindow: 'Check In Window',
    assignToMe: 'Assign to Me',
    internalNotes: 'QTS Internal Notes',
    internalNotesPlaceHolder: 'Your notes',
    startStopWatch: 'Start Stopwatch',
    timeCard: 'Time Card',
    completedTaskLabel: 'I have completed this task',
    submitLabel: 'Submit',
    cancelLabel: 'Cancel',
    noEscortFound: 'No Escort & Stays Today',
    timeLogError: 'Time Log is required and please enter in HH:MM format.',
    escortUpdateSuccess: 'Escort updated successfully.',

    roundHeader: 'Rounds',
    createCorrectiveMaintenance: 'Create Corrective Maintenance',
    roundCompletedTaskLabel:
      'I have completed this task (sight, sound, smell and digital inspections in all spaces)',
    building: 'Campus/Building',
    noRoundFound: 'No Rounds Found',
    roundUpdateSuccess: 'Round updated successfully.',
    selectValidData: 'Please Select Correct Data',
  },
  REMOTE_HAND_FORM: {
    details: 'Details',
    requester: 'Requester',
    callMe: 'Call Me',
    attachments: 'Attachments',
    assigntoMe: 'Assign to Me',
    approval: 'Approval',
    select: 'Select',
    state: 'State',
    workNotes: 'Work Notes',
    documentation: 'Documentation',
    updateTimeCard: 'Update Time Card',
    remoteHandsOrderDetails: ' Remote Hands Order Details',
  },

  CORRECTIVE_MANAGE_MAINTENANCE_FORM: {
    maintenanceCalendar: 'Maintenance Calendar',
    manageMaintenance: 'Manage Corrective Maintenance',
    workOrderDetails: 'Work Order Details',
    updateWorkOrder: 'Update Work Order Details',
    tableSearchPlaceholder: 'Search by work order, device name or state',
    resolutionCode: 'Resolution Code',
    select: 'Select',
    isRequired: ' is required',
    state: 'State',
    name: 'Name',
    assignedToMe: 'Assign to Me',
    comments: 'Comments',
    documentation: 'Documentation',
    serviceProvider: 'Service Provider',
    externalTechnician: 'External Technician',
    nameOfTech: 'Name of Tech',

    commentHistory: 'Comment History',

    changeRecordDetails: 'Change Record Details',
    updateChangeRecord: 'Update Change Record',
    notificationType: 'Notification Type',
    ciSearch: 'CI Search',
    notifyAllCustomersInBuilding: 'Notify all customers in building',
    closeCode: 'Close Code',
    closeNotes: 'Close Notes',
    completedMOP: 'Completed MOP',
    postImplementationReview: 'Post-Implementation Review',

    timeCard: 'Time Card',
    updateTimeCard: 'Update Time Card',
    timeLog: 'Time Log',
    timeLogTitle: 'Time Log (HH:MM)',
    enterTimeLog: 'Enter time log',
    timeLogError: 'Time Log is required and please enter in HH:MM format.',

    submit: 'Submit',
    cancel: 'Cancel',
    save: 'Save',
    close: 'Close',
    startDate: 'Start Date',
    window: 'Window',
    dashBoard: 'Dashboard',
    resolutionDetail: 'Resolution Detail',
    laborAdded: 'Labor Added',
    summaryOf: 'Summary of the Corrective Work Order associated',
  },

  changeDetails: "Change Details",
};

export const SPAGE_BREAD_CRUMB: Record<
  string,
  {
    backUrl?: string;
    heading: string;
    breadcrumb: Array<Breadcrumb>;
  }
> = {
  remoteHands: {
    heading: 'Manage Remote Hands',
    backUrl: 'actions-list/remote-hands/list',
    breadcrumb: [
      { title: 'Action Overview', url: 'actions-list' },
      { title: 'Remote Hands', url: 'actions-list/remote-hands/list' },
    ],
  },
};
