export const maintenanceListColumns = [
  {
    field: 'number',
    size: 'sm',
    label: 'Work Order Number',
    active: true,
    disabled: true,
  },
  {
    field: 'type',
    size: 'sm',
    label: 'Work Order Type',
    active: true,
  },
  {
    field: 'assetName',
    size: 'md',
    label: 'Device Name',
    active: true,
    disabled: true,
  },
  {
    field: 'status',
    size: 'sm',
    label: 'State',
    active: true,
    disabled: true,
  },
  {
    field: 'assignedToName',
    size: 'sm',
    label: 'Assigned To',
    active: true,
    disabled: true,
  },
  
  {
    field: 'description',
    size: 'sm',
    label: 'Order Description',
    active: true,
  },

  {
    field: 'assignmentGroupName',
    size: 'sm',
    label: 'Assignment Group',
    active: true,
  },
  {
    field: 'workLocationName',
    size: 'sm',
    label: 'Location',
    active: true,
  },
  {
    field: 'initiationReason',
    size: 'sm',
    label: 'Initiation Reason',
    active: true,
  },
  {
    field: 'reportedByName',
    size: 'sm',
    label: 'Reported By',
    active: true,
  },
  {
    field: 'updatedOn',
    size: 'sm',
    label: 'Updated Date',
    active: true,
  },

  {
    field: 'campusName',
    size: 'sm',
    label: 'Campus',
    active: true,
  },
  {
    field: 'buildingName',
    size: 'sm',
    label: 'Building',
    active: true,
  },

  {
    field: 'parentWorkOrderNumber',
    size: 'sm',
    label: 'Parent Work Order',
    active: true,
  },

  {
    field: 'priority',
    size: 'sm',
    label: 'Priority',
    active: false,
  },
  {
    field: 'serviceProviderName',
    size: 'sm',
    label: 'Service Provider',
    active: false,
  },
  {
    field: 'externalTechnician',
    size: 'sm',
    label: 'External Technician',
    active: false,
  },
  {
    field: 'capital',
    size: 'sm',
    label: 'Capital',
    active: false,
  },
  {
    field: 'orderSummary',
    size: 'sm',
    label: 'Order Summary',
    active: false,
  },
  {
    field: 'changeRequestNumber',
    size: 'sm',
    label: 'Change Request',
    active: true,
  },
];
