
export interface IWorkOrder {
  pageInfo: PageInfo
  data: Daum[]
}

export interface PageInfo {
  pageSize: number
  totalCount: number
  totalPages: number
  pageNumber: number
}

export interface Daum {
  id: number
  sysId: string
  number: string
  name: string
  state: string
  assetType: string
  assetSysId: string
  assetName: string
  scheduledStartTime: string
  campusSysId: string
  campusName: string
  buildingSysId: string
  buildingName: string
  assignedToSysId: string
  assignedToUsername: string
  assignedToName: string
  maintenanceWindowStartTime: string
  maintenanceWindowEndTime: string
}
  