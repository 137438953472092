import { Injectable, inject } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  forkJoin,
  map,
  of,
  shareReplay,
  tap,
} from 'rxjs';
import { maintenanceListColumns } from './maintenance.mock/maintenance.list-columns.mock';
import {
  Column,
  SearchAppliedFilter,
  SearchFilter,
  SearchListState,
  Option,
  Response,
} from '@qts-sdp-ui/shared-data';
import {
  CHANGE_REQUEST_CODE,
  MAINTENANCE_STATE_CODE,
  QosCommonService,
  WORK_ORDER_TYPE_CODE,
  columnDisplayName,
} from '@qts-qos-ui/qos-app-data';
import {
  DATE_FORMATS,
  DateTimeService,
  HttpService,
  toOption,
} from '@qts-sdp-ui/shared-util';
import {
  FILTER_CONFIG_KEY,
  MaintenanceListData,
  MaintenanceTable,
} from '../../models/maintenance';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceListService {
  private readonly dateTimeService = inject(DateTimeService);
  public readonly httpService = inject(HttpService);
  private commonService = inject(QosCommonService);

  public listState: SearchListState = {};
  public config: any = null;
  public filterConfigSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    this.config
  );
  public filterConfig$ = this.filterConfigSubject.asObservable();
  private totalItems = 0;
  public loading = false;

  public isDefaultFilterRemoved = false;

  public appliedFilters: SearchAppliedFilter[] = [];
  public appliedFilterChange: Subject<SearchAppliedFilter | null> =
    new Subject<SearchAppliedFilter | null>();

  fetchColumns(): Observable<Column[]> {
    return of(maintenanceListColumns as Column[]);
  }

  get results() {
    return this.totalItems;
  }

  public clearListState(): void {
    this.listState = {};
  }

  public clearAllFilters() {
    this.appliedFilters = [];
    this.listState.filters = {};
  }

  private rollFilters(filters: SearchFilter): string {
    const listed = Object.keys(filters).map((key: string) => {
      const filterKey = key;
      const filterVal = filters[key];
      if (Array.isArray(filterVal)) {
        if (key === 'serviceProviders') {
          const formattedValue = filterVal.map((value) => value.name).join(',');
          return `&${filterKey}=${formattedValue}`;
        } else {
          const formattedValue = filterVal.map((value) => value.code).join(',');
          return `&${filterKey}=${formattedValue}`;
        }
      } else if (key === 'updatedDateFrom') {
        return `&${key}=${this.commonService.convertDateFormatForRequestV1(
          new Date(filterVal as string)
        )}`;
      } else if (key === 'updatedDateTo') {
        const date = new Date(filterVal as string)
        date.setHours(23)
        date.setMinutes(59)
        date.setSeconds(59)
        return `&${key}=${this.commonService.convertDateFormatForRequestV1(
          date
        )}`;
      } else if (typeof filterVal === 'string') {
        return `&${key}=${filterVal}`;
      } else if (key === 'deviceNames') {
        return `&${key}=${filterVal.name}`;
      } else {
        return `&${key}=${filterVal.code}`;
      }
    });

    return listed.join('');
  }

  private injectAppliedFilters(
    filters: SearchAppliedFilter[]
  ): SearchAppliedFilter | null {
    let updatedFilter: SearchAppliedFilter | null = null;

    if (!Object.keys(filters).length) {
      this.appliedFilters.splice(0, this.appliedFilters.length);
      return null;
    }
    filters.forEach((filter: SearchAppliedFilter) => {
      let selectedIndex = -1;
      const isAlreadySelected = this.appliedFilters.find(
        (appliedFilter: SearchAppliedFilter, index: number) => {
          const isSameField = appliedFilter.field === filter.field;
          if (isSameField) selectedIndex = index;
          return isSameField;
        }
      );

      if (!isAlreadySelected) {
        this.appliedFilters.push(filter);
        updatedFilter = filter;
      } else if (isAlreadySelected.label !== filter.label) {
        this.appliedFilters.splice(selectedIndex, 1);
        this.appliedFilters.push(filter);
        updatedFilter = filter;
      }
    });
    return updatedFilter;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private checkDefaultFilterRemoved(appliedFilter: SearchAppliedFilter) {
    // if (
    //   appliedFilter.field === FILTER_CONFIG_KEY.orderStatus &&
    //   appliedFilter.selectedValues
    // ) {
    //   const defaultFilterNames = CHANGES_DEFAULT_FILTER.map((filter) =>
    //     filter.name.toLocaleLowerCase()
    //   );
    //   const selectedValues = appliedFilter.selectedValues as Option[];
    //   const isAllDefaultFilters = selectedValues.every((value) =>
    //     defaultFilterNames.includes(value.name)
    //   );
    //   if (
    //     isAllDefaultFilters &&
    //     defaultFilterNames.length === selectedValues.length
    //   ) {
    //     this.isDefaultFilterRemoved = true;
    //   }
    // }
  }

  public removeAppliedFilter(index: number): void {
    const appliedFilter: SearchAppliedFilter = this.appliedFilters[index];
    if (appliedFilter.type !== 'search') {
      this.checkDefaultFilterRemoved(appliedFilter);
      appliedFilter.field &&
        delete this.listState.filters?.[appliedFilter.field];
    } else {
      this.listState.search = '';
    }
    this.appliedFilters.splice(index, 1);
    appliedFilter.label = '';
    appliedFilter.selectedValues = undefined;
    this.appliedFilterChange.next(appliedFilter);
  }

  public processAppliedFilters(
    input: SearchListState,
    refreshTable = false
  ): void {
    const { search, filters } = input;
    if (search?.trim().length) {
      const previousIdx = this.appliedFilters.findIndex(
        (filter: SearchAppliedFilter) => filter.type === 'search'
      );
      if (previousIdx !== -1) {
        this.appliedFilters.splice(previousIdx, 1);
      }
      this.appliedFilters.push({ type: 'search', label: search });
    } else {
      const searchIndex = this.appliedFilters.findIndex(
        (filter) => filter.type === 'search' && filter.label?.length
      );
      this.listState.search = '';
      if (searchIndex !== -1) {
        this.appliedFilters.splice(searchIndex, 1);
      }
    }

    if (filters) {
      const formattedFilters = Object.keys(filters).map((key: string) => {
        const filterVal = filters[key];

        const returnVal: SearchAppliedFilter = {
          type: 'filter',
          field: key,
          label: '',
          selectedValues: filterVal,
        };

        if (Array.isArray(filterVal)) {
          const totalFilterItemCount = this.config?.[key]?.length ?? 0;
          const isAllItemSelected =
            filterVal.length !== 0 && filterVal.length === totalFilterItemCount;
          if (isAllItemSelected) {
            returnVal.label = `${columnDisplayName[key]}: All`;
          } else {
            const selectedValues = filterVal
              .map((option: Option) => option.name)
              .join(', ');
            returnVal.label = `${columnDisplayName[key]}: ${selectedValues}`;
          }
        } else if (key === 'updatedDateFrom' || key === 'updatedDateTo') {
          const formattedDate = this.dateTimeService.formate(
            new Date(filterVal as string).toISOString()
          );
          returnVal.label = `${columnDisplayName[key]}: ${formattedDate}`;
        } else if (typeof filterVal === 'string') {
          returnVal.label = `${columnDisplayName[key]}: ${filterVal}`;
        } else {
          returnVal.label = `${columnDisplayName[key]}: ${filterVal.name}`;
        }
        return returnVal;
      });
      const updatedFilter = this.injectAppliedFilters(formattedFilters);
      if (refreshTable && (updatedFilter || !Object.keys(filters).length)) {
        this.appliedFilterChange.next(updatedFilter);
      }
    }
  }

  private mergeListState(input: SearchListState): void {
    const filters = { ...this.listState.filters, ...input.filters };
    this.listState = { ...this.listState, ...input };
    this.listState.filters = filters;
  }

  /**
   * Helper to return full url of the get power circuit api
   * @returns url in string format
   */
  private getApiUrl(): string {
    const sortValue =
      this.listState.sort && this.listState.column
        ? `&sort=${this.listState.column}.${this.listState.sort}`
        : '';

    const searchValue = this.listState.search
      ? `&query=${encodeURIComponent(this.listState.search.trim())}`
      : '';

    const filters = this.listState.filters
      ? this.rollFilters(this.listState.filters)
      : '';

    return `${this.commonService.maintenanceList}?pageNumber=${this.listState.page}&pageSize=${this.listState.size}${sortValue}${searchValue}${filters}`;
  }

  public fetchDataList(
    input: SearchListState,
    isInitialFetch = false
  ): Observable<Response<MaintenanceListData>> {
    this.processAppliedFilters(input);
    if (!isInitialFetch)
      this.commonService.setFilterAsQueryString(
        input,
        this.appliedFilters.filter((filter) => filter.type !== 'search')
      );
    this.mergeListState(input);
    this.loading = true;

    return this.httpService
      .get<Response<MaintenanceListData>>(this.getApiUrl())
      .pipe(
        tap((res: Response<MaintenanceListData>) => {
          this.loading = false;
          this.totalItems = res.pageInfo.totalCount;
        })
      );
    // return of(maintenanceList)
  }

  /**
   * Helper function to map api response properties to UI table columns
   * @param list
   * @returns PowerCircuitTable[]
   */
  public decorateData(list: MaintenanceListData[]): MaintenanceTable[] {
    return list.map((response: MaintenanceListData) => {
      return {
        ...response,
        status: {
          label: response.state ? response.state : 'New',
          code: response.state ? response.state.toUpperCase() : 'NEW',
          color: this.getColorFromCode(response.state?.toUpperCase()),
        },
        type: {
          label: response.workOrderType
            ? response.workOrderType.split(' ')[0]
            : 'New',
          code: response.workOrderType ? response.state.toUpperCase() : 'NEW',
          color: this.getColorFromCode(response.workOrderType?.toUpperCase()),
        },
        capital: response.capital ? 'Yes' : 'No',
        orderSummary: response.name,
        updatedOn: this.dateTimeService.formate(
          response.updatedOn ?? '',
          DATE_FORMATS.M_D_YYYY
        ),
      } as MaintenanceTable;
    });
  }

  public fetchConfigs() {
    return forkJoin({
      [FILTER_CONFIG_KEY.buildings]: this.getBuildings(),
      [FILTER_CONFIG_KEY.campuses]: this.getCampuses(),
      [FILTER_CONFIG_KEY.assignedGroup]: this.getAssignedGroup(),
      [FILTER_CONFIG_KEY.assignedTo]: this.getGroupUsers(),
    });
  }

  getColorFromCode(code: string) {
    let color = 'blue';
    switch (code) {
      case MAINTENANCE_STATE_CODE.PENDING_REVIEW:
      case WORK_ORDER_TYPE_CODE.CORRECTIVE_MAINTENANCE:
        color = 'yellow';
        break;
      case MAINTENANCE_STATE_CODE.CLOSED_CANCELLED:
      case MAINTENANCE_STATE_CODE.CLOSED_COMPLETE:
      case WORK_ORDER_TYPE_CODE.PREVENTATIVE_MAINTENANCE:
        color = 'grey';
        break;
      case MAINTENANCE_STATE_CODE.WORK_IN_PROGRESS:
        color = 'green';
        break;
      case MAINTENANCE_STATE_CODE.ON_HOLD:
        color = 'red';
        break;
      default:
        color = 'blue';
        break;
    }
    return color;
  }

  public getBuildings() {
    return this.httpService.get<any>(this.commonService.buildings).pipe(
      map((res: any[]) =>
        res.map(
          (response) =>
          ({
            name: response.name,
            code: response.name,
            campusName: response.campusName,
          } as Option)
        )
      )
    );
  }

  public getCampuses() {
    return this.httpService
      .get<any>(this.commonService.campuses)
      .pipe(shareReplay(), toOption({ codeFieldName: 'name' }));
  }

  public getAssignedGroup() {
    return this.httpService.get<any>(this.commonService.assignedGroup).pipe(
      shareReplay(),
      map((response: string[]) =>
        response.map((res) => ({ name: res, code: res } as Option))
      )
    );
  }

  public getGroupUsers() {
    return this.httpService.get<any>(this.commonService.groupUsers).pipe(
      shareReplay(),
      map((response: any[]) =>
        response.map(
          (res) => ({ name: res.name, code: res.userName } as Option)
        )
      )
    );
  }
}
