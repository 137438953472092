/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  inject,
  ChangeDetectorRef,
} from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth, { AuthState } from '@okta/okta-auth-js';
import {
  QosUserService,
  COT_READ_ONLY_USER,
  QosCommonService,
  StopWatchService,
  AppSettings,
  getSettings,
} from '@qts-qos-ui/qos-app-data';
import {
  QtsGlobalLayoutComponent,
  QtsQosLayoutComponent,
  QtsStopWatchComponent,
} from '@qts-sdp-ui/shared-ui';
import { HelperService } from '@qts-sdp-ui/shared-util';
import { Subscription } from 'rxjs';
import { STOPWATCH, TimeLogEntries } from '@qts-sdp-ui/shared-data';
import { FormControl } from '@angular/forms';
import { MaintenanceListService } from '@qts-qos-ui/maintenance-data';
import {
  ActionsListService,
  TABS_NAVIGATION,
} from '@qts-qos-ui/actions-list-app-data';

@Component({
  selector: 'qos-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    CommonModule,
    RouterOutlet,
    QtsGlobalLayoutComponent,
    QtsQosLayoutComponent,
    QtsStopWatchComponent,
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  public title = '';
  private readonly helperService = inject(HelperService);
  public actionListService = inject(ActionsListService);
  private appSettings: AppSettings;

  public showStopWatch = false;
  public isTimerRunning = false;

  public nav = {
    role: 'customer' as 'associate' | 'customer',
    menuItems: [
      {
        title: '',
        path: 'dashboard',
        loadChildren: () =>
          import('@qts-qos-ui/qos-app-feature').then(
            (r) => r.qosAppFeatureRoutes
          ),
        itemLists: {
          mainContent: [],
          sidebar: [],
        },
      },

      // {
      //   title: 'Catalog',
      //   path: 'catalog',
      //   loadChildren: () =>
      //     import('@qts-qos-ui/catalog-app').then((r) => r.CATALOG_ROUTES),
      //   itemLists: {
      //     mainContent: [],
      //     sidebar: [],
      //   },
      // },
    ],
    account: {
      initials: 'AT',
      email: 'username@email.com',
    },
    profileMenuItems: [
      { text: 'Back to Dashboard', path: '/' },
      {
        text: 'Actions Overview',
        path: '/actions-list/maintenance',
        preNavigation: () => {
          this.actionListpreNavigation();
        },
      },
      { text: 'Log Out', path: '' },
    ],
    iconsUrls: {
      dashboardUrl: '/',
      calendarUrl: '/maintenance-calendar',
    },
    notificationsAmount: 0,
    notificationsList: [],
    legacyNav: [],
    associateNav: [],
  };

  public isAuthenticated = false;
  private readonly router = inject(Router);
  public cotReadOnlyUser = false;
  // Time Log
  private subscriptionHolder = new Subscription();
  public timer = 0;
  public timeLogEntriesList: TimeLogEntries[] = [];
  public startedTimeCard!: TimeLogEntries | null;
  public stopWatchLabels = STOPWATCH;
  public isTimeLogLoading = false;
  public isTimerRunningUpdateTimer = false;
  public allTimeCardState: { [key: number]: number } = {};
  public searchControl: FormControl = new FormControl('');
  public isLoadingSearch = false;
  public searchResultTimeLog: any[] = [];
  public userInfo: any = {};

  constructor(
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    private ref: ChangeDetectorRef,
    private qosUserService: QosUserService,
    private stopWatchService: StopWatchService,
    private qosCommonService: QosCommonService,
    private maintenanceListService: MaintenanceListService
  ) {
    this.appSettings = getSettings();
  }

  actionListpreNavigation() {
    this.actionListService.clearAllFilter.next(TABS_NAVIGATION.MAINTENANCE);
    this.maintenanceListService.clearAllFilters();
  }

  async ngOnInit(): Promise<void> {
    this.oktaAuth.authStateManager.subscribe(this.authStateChanged.bind(this));
    this.handleStopWatchStateChange();
    const authState = await this.oktaAuth.authStateManager.getAuthState();
    const prevValue = this.isAuthenticated;
    this.isAuthenticated = !!authState?.isAuthenticated;

    if (prevValue != this.isAuthenticated) {
      this.ref.markForCheck();
    }
    this.qosUserService.loadUserData();
    this.getUserContextQos();
    if (
      this.appSettings.QOSPendoKey &&
      this.appSettings.QOSPendoKey.trim().length > 0
    ) {
      this.addPendoScript(this.appSettings.QOSPendoKey);
    }
  }

  addPendoScript(key) {
    (function (apiKey) {
      // eslint-disable-next-line
      (function (p, e, n, d, o) {
        let w, x;
        o = p[d] = p[d] || {};
        o._q = o._q || [];
        const v = [
          'initialize',
          'identify',
          'updateOptions',
          'pageLoad',
          'track',
        ];
        for (w = 0, x = v.length; w < x; ++w)
          (function (m) {
            // eslint-disable-next-line prefer-rest-params
            o[m] =
              o[m] ||
              function () {
                o._q[m === v[0] ? 'unshift' : 'push'](
                  // eslint-disable-next-line prefer-rest-params
                  [m].concat([].slice.call(arguments, 0))
                );
              };
          })(v[w]);
        const y = e.createElement(n);
        y['async'] = !0;
        y['src'] =
          'https://us1.cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
        const z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);
      })(window, document, 'script', 'pendo');
    })(key);
  }

  handleStopWatchStateChange() {
    this.stopWatchService.getStopWatchStateObservable().subscribe((state) => {
      if (state.selectedTimeLog) {
        this.isTimerRunning = true;
      } else {
        this.isTimerRunning = false;
      }
    });
  }

  private authStateChanged(authState: AuthState): void {
    this.isAuthenticated = !!authState?.isAuthenticated;
    if (!this.isAuthenticated) {
      this.router.navigate(['/authentication']);
    }
  }

  logOutFunction() {
    if (this.oktaAuth?.isAuthenticated()) {
      this.oktaAuth.signOut();
    }
  }

  getUserContextQos() {
    this.qosUserService.userContext$.subscribe((userInfo: any) => {
      if (userInfo) {
        const fullName: string = userInfo.firstName + ' ' + userInfo.lastName;
        this.nav.account.initials = this.helperService.getInitials(
          fullName ?? ''
        );
        this.nav.account.email = userInfo.email;
        this.cotReadOnlyUser = userInfo?.roles.some((item: any) => {
          return item == COT_READ_ONLY_USER;
        });
        this.stopWatchService.getTimeLogEntriesList().subscribe((entries) => {
          const isStopwatchRunning = entries?.find(
            (entry) => entry.isStopwatchRunning
          );
          if (isStopwatchRunning) {
            this.isTimerRunning = true;
          }
        });
        this.includePendo(userInfo);
      }
    });
  }

  includePendo(userInfo) {
    (window as any).pendo?.initialize({
      visitor: {
        id: userInfo.username,
        email: userInfo.email,
        full_name: userInfo.firstName + ' ' + userInfo.lastName,
        role: userInfo.roles?.join(', '),
      },
      disableGuidePseudoStyles: true
    });
  }

  toggleStopWatch() {
    this.showStopWatch = !this.showStopWatch;
    if (this.showStopWatch) {
      this.fetchTimeLogEntries();
      this.handleStopWatchStateChangeData();
    }
  }

  closeTimeLogModel() {
    this.showStopWatch = false;
  }

  plusIconClick() {
    this.router.navigate(['/dashboard/create-corrective-maintenance-form']);
    this.qosCommonService.routeURLSubject.next(this.router.url);
  }

  public fetchTimeLogEntries() {
    this.isTimeLogLoading = true;
    this.subscriptionHolder.add(
      this.stopWatchService.getTimeLogEntriesList().subscribe((entries) => {
        const startedTimerCard = entries.find(
          (entry) => entry.isStopwatchRunning
        );
        if (startedTimerCard) {
          this.timer = startedTimerCard?.stopwatch.totalTimeRecorded ?? 0;
          this.stopWatchService.updateFilterState({
            selectedTimeLog: { ...startedTimerCard },
          });
          this.isTimerRunningUpdateTimer = true;
          // this.updateTimer();
        }
        this.timeLogEntriesList = entries;
        entries.forEach(
          (item) =>
          (this.allTimeCardState[item.id] =
            item.stopwatch?.totalTimeRecorded ?? 0)
        );
        this.isTimeLogLoading = false;
      })
    );
  }

  handleStopWatchStateChangeData() {
    this.subscriptionHolder.add(
      this.stopWatchService.getStopWatchStateObservable().subscribe((state) => {
        this.startedTimeCard = state.selectedTimeLog;
      })
    );
  }

  filterOptionEmitter(searchQuery: string) {
    this.fetchSearchTimeLogEntries(searchQuery);
  }

  fetchSearchTimeLogEntries(searchQuery: string) {
    if (searchQuery && searchQuery.length > 0) {
      this.isLoadingSearch = true;
      this.stopWatchService
        .getTimeLogEntriesBySearch(true, searchQuery)
        .subscribe((res: any) => {
          this.searchResultTimeLog = res?.data;
          this.isLoadingSearch = false;
        });
    } else {
      this.searchResultTimeLog = [];
      this.isLoadingSearch = false;
    }
  }

  selectedValueForAddTimeLog(data: any) {
    const saveData: any = {
      type: data?.type,
      id: data?.id,
    };
    this.isTimeLogLoading = true;
    this.stopWatchService
      .saveSearchDataToTimeLogEntry(saveData)
      .subscribe(() => {
        this.fetchTimeLogEntries();
      });
  }

  updateFilterState(event: any) {
    if (event.action == 'stop') {
      this.stopWatchService.updateFilterState({
        selectedTimeLog: null,
      });
    } else {
      this.stopWatchService.updateFilterState({
        selectedTimeLog: { ...event?.entries },
      });
    }
  }

  stopStartStopWatch(event: any) {
    const payLoad = {
      type: event?.entries?.type,
      id: event?.entries?.id,
    };
    if (event.action == 'stop' && event?.entries) {
      this.stopWatchService
        .stopStopWatch(payLoad)
        .subscribe(
          () =>
          (event.entries.isStopwatchRunning =
            !event.entries.isStopwatchRunning)
        );
    } else {
      this.stopWatchService
        .startStopWatch(payLoad)
        .subscribe(
          () =>
          (event.entries.isStopwatchRunning =
            !event.entries.isStopwatchRunning)
        );
    }
  }

  deleteTimeLogEntry(timeLog: any) {
    this.isTimeLogLoading = true;
    this.stopWatchService
      .deleteTimeLogEntry(timeLog.type, timeLog.id)
      .subscribe(() => {
        this.fetchTimeLogEntries();
      });
  }

  ngOnDestroy(): void {
    this.oktaAuth.authStateManager.unsubscribe(
      this.authStateChanged.bind(this)
    );
    this.subscriptionHolder.unsubscribe();
  }
}
