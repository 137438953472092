export const remoteHandsListColumns = [
  {
    field: 'number',
    size: 'sm',
    label: 'WO #',
    active: true,
    disabled: true,
  },
  {
    field: 'company',
    size: 'sm',
    label: 'Company',
    active: true,
    disabled: true,
  },
  {
    field: 'status',
    size: 'sm',
    label: 'Priority',
    active: true,
    disabled: true,
  },
  {
    field: 'remoteHandType',
    size: 'sm',
    label: 'Type',
    active: true,
    disabled: true,
  },
];
