import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { DateTimeService } from '@qts-sdp-ui/shared-util';

@Injectable({
  providedIn: 'root',
})
export class ActionsListService {
  private readonly dateTimeService = inject(DateTimeService);
  private readonly httpClient = inject(HttpClient);

  public totalCount: Subject<number> = new Subject<number>();
  public activeTabIndex: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  public clearAllFilter: Subject<string> = new Subject<string>();
  public showFiltersSideBar = new BehaviorSubject<boolean>(false);

  public setShowFiltersSideBar(value: boolean): void {
    this.showFiltersSideBar.next(value);
  }

  public getShowFiltersSideBar(): boolean {
    return this.showFiltersSideBar.getValue();
  }
}
