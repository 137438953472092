import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NOTIFICATION_SEVERITY,
  NotificationDetail,
  SearchAppliedFilter,
  SearchListState,
  TypedFormGroup,
  UserInfo,
  Option,
  GenericType,
} from '@qts-sdp-ui/shared-data';
import {
  AuthOktaService,
  DATE_FORMATS,
  DateTimeService,
  HttpService,
} from '@qts-sdp-ui/shared-util';
import { Observable, from, shareReplay, switchMap, filter, map } from 'rxjs';
import { URL_CONSTANTS, URL_NAMES } from './qos-common-url';
import { ValidatorFn } from '@angular/forms';
import { formatDate } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { ICorrectiveMaintenanceForm } from './qos-common.model';
import { AppSettings, getSettings } from './app.settings';
import { CorrectiveMaintenanceResponse } from '@qts-qos-ui/maintenance-calender-data';

@Injectable({
  providedIn: 'root',
})
export class QosCommonService {
  private apiUrls: { [key: string]: string } = {};
  private readonly http = inject(HttpService);
  private readonly router = inject(Router);
  private readonly route = inject(ActivatedRoute);
  private readonly appSettings: AppSettings;

  private baseUrl = '';

  constructor(
    private authOktaService: AuthOktaService,
    private dateTimeService: DateTimeService
  ) {
    this.appSettings = getSettings();
    this.baseUrl = this.appSettings.QOSRestUrl;
    //.replace('qosapidev', 'qosapiqa');
    this.apiUrls[
      URL_NAMES.GET_WORK_ORDER_DETAILS_VIEW
    ] = `${this.baseUrl}${URL_CONSTANTS.GET_WORK_ORDER_DETAILS_VIEW}`;

    this.apiUrls[
      URL_NAMES.GET_SERVICE_PROVIDER
    ] = `${this.baseUrl}${URL_CONSTANTS.GET_SERVICE_PROVIDER}`;

    this.apiUrls[
      URL_NAMES.UPDATE_MANAGE_MAINTENANCE
    ] = `${this.baseUrl}${URL_CONSTANTS.UPDATE_MANAGE_MAINTENANCE}`;
    this.apiUrls[
      URL_NAMES.GET_WORK_ORDER_DETAILS_BY_ID
    ] = `${this.baseUrl}${URL_CONSTANTS.GET_WORK_ORDER_DETAILS_BY_ID}`;
    this.apiUrls[
      URL_NAMES.GET_ASSETS
    ] = `${this.baseUrl}${URL_CONSTANTS.GET_ASSETS}`;
    this.apiUrls[
      URL_NAMES.GET_REPORTED_BY
    ] = `${this.baseUrl}${URL_CONSTANTS.GET_REPORTED_BY}`;
    this.apiUrls[
      URL_NAMES.SUBMIT_CORRECTIVE_MAINTENANCE
    ] = `${this.baseUrl}${URL_CONSTANTS.SUBMIT_CORRECTIVE_MAINTENANCE}`;
    this.apiUrls[
      URL_NAMES.UPLOAD_CORRECTIVE_WORK_ORDER_DOC
    ] = `${this.baseUrl}${URL_CONSTANTS.UPLOAD_CORRECTIVE_WORK_ORDER_DOC}`;
  }

  get baseRestUrl() {
    return this.baseUrl;
  }

  public getApiUrl(urlName: URL_NAMES, category = ''): string {
    return `${this.apiUrls[urlName].replace('{PARAM}', category)}`;
  }

  showSuccess(detail: NotificationDetail) {
    return {
      severity: NOTIFICATION_SEVERITY.SUCCESS,
      summary: 'Success',
      ...detail,
    };
  }

  showError(detail: NotificationDetail) {
    return {
      severity: NOTIFICATION_SEVERITY.ERROR,
      summary: 'Error',
      ...detail,
    };
  }

  public fetchCompanyById(): Observable<any> {
    return this.http.get<any>('');
  }

  public convertDateFormatForRequest(
    inputDate?: string,
    dateFormat?: string
  ): string {
    if (!inputDate) return '';
    const formatedInputDate = inputDate?.replace(' +0000', '');
    const date = new Date(formatedInputDate);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const returnMonth = month < 10 ? '0' + month : month;
    const returnDay = day < 10 ? '0' + day : day;

    if (dateFormat === DATE_FORMATS.MM_DD_YYYY) {
      return `${returnMonth}-${returnDay}-${year}`;
    } else {
      return `${year}-${returnMonth}-${returnDay}`;
    }
  }

  public getUserContextQos() {
    return from(this.authOktaService.isUserAuthenticated()).pipe(
      filter((value) => !!value),
      switchMap(() =>
        this.http.get<UserInfo>(this.userContextUrl).pipe(shareReplay())
      )
    );
  }

  //Api Service Url
  get userContextUrl() {
    return `${this.baseUrl}${URL_CONSTANTS.GET_USER_CONTEXT}`;
  }

  get campuses() {
    return `${this.baseUrl}${URL_CONSTANTS.GET_USER_CONTEXT_CAMUSES}`;
  }

  get buildings() {
    return `${this.baseUrl}${URL_CONSTANTS.GET_USER_CONTEXT_BUILDING}`;
  }

  get assetTypes() {
    return `${this.baseUrl}${URL_CONSTANTS.GET_ASSET_TYPES}`;
  }

  get maintenanceDefinitions() {
    return `${this.baseUrl}${URL_CONSTANTS.GET_MAINTENANCE_DEFINITIONS}`;
  }

  get groupUsers() {
    return `${this.baseUrl}${URL_CONSTANTS.GET_GROUP_USERS}`;
  }

  get assignedGroup() {
    return `${this.baseUrl}${URL_CONSTANTS.GET_ASSIGNED_GROUP}`;
  }

  get actionSummary() {
    return `${this.baseUrl}${URL_CONSTANTS.GET_ACTION_SUMMARY}`;
  }

  get myRemoteHandTickets() {
    return `${this.baseUrl}${URL_CONSTANTS.GET_MY_REMOTE_HANDS_TICKETS}`;
  }

  get escort() {
    return `${this.baseUrl}${URL_CONSTANTS.GET_ESCORT}`;
  }

  get remoteHandListById() {
    return `${this.baseUrl}${URL_CONSTANTS.GET_REMOTE_HAND_LIST_BY_ID}`;
  }
  get updateRemoteHandById() {
    return `${this.baseUrl}${URL_CONSTANTS.UPDATE_REMOTE_HAND_LIST_BY_ID}`;
  }
  get updateEscort() {
    return `${this.baseUrl}${URL_CONSTANTS.UPDATE_ESCORT}`;
  }

  get updateRound() {
    return `${this.baseUrl}${URL_CONSTANTS.UPDATE_ROUND}`;
  }

  get rounds() {
    return `${this.baseUrl}${URL_CONSTANTS.GET_ROUNDS}`;
  }

  get changeDetailsbyId() {
    return `${this.baseUrl}${URL_CONSTANTS.GET_CHANGE_DETAILS_BY_ID}`;
  }

  getCurrentWeekDates(): { date: any; dayOfWeek: string }[] {
    const now = new Date();
    const startOfWeek = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - now.getDay()
    );

    const weekDates = [];
    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(startOfWeek);
      currentDate.setDate(startOfWeek.getDate() + i);
      const dayOfWeek = this.getDayOfWeek(currentDate.getDay());
      const formattedDate = formatDate(currentDate, 'yyyy-MM-dd', 'en-US'); // Format as yyyy-MM-dd
      weekDates.push({ date: formattedDate, dayOfWeek: dayOfWeek });
    }
    return weekDates;
  }

  getDayOfWeek(day: number): string {
    const daysOfWeek = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    return daysOfWeek[day];
  }

  getCurrentWeekRange(): string {
    const now = new Date();
    const startOfWeek = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - now.getDay()
    );
    const endOfWeek = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + (6 - now.getDay())
    );

    const startMonthAbbreviation = this.getMonthAbbreviation(
      startOfWeek.getMonth()
    );
    const endMonthAbbreviation = this.getMonthAbbreviation(
      endOfWeek.getMonth()
    );
    const startDay = startOfWeek.getDate();
    const endDay = endOfWeek.getDate();
    if (startMonthAbbreviation == endMonthAbbreviation) {
      return `${startMonthAbbreviation} ${startDay}-${endDay}`;
    } else {
      return `${startMonthAbbreviation} ${startDay}-${endMonthAbbreviation} ${endDay}`;
    }
  }

  getMonthAbbreviation(month: number): string {
    const monthsAbbreviation = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    return monthsAbbreviation[month];
  }

  public convertTimeToSeconds(timeString: string): number {
    const [hours, minutes] = timeString.split(':').map(Number);
    return hours * 3600 + minutes * 60;
  }

  public removeValidators<T>(
    formGroup: TypedFormGroup<T>,
    controlNames: (keyof T)[] | keyof T,
    validator: ValidatorFn | ValidatorFn[]
  ): void {
    if (Array.isArray(controlNames)) {
      for (const name of controlNames) {
        formGroup[name].removeValidators(validator);
        formGroup[name].updateValueAndValidity();
      }
    } else {
      formGroup[controlNames].removeValidators(validator);
      formGroup[controlNames].updateValueAndValidity();
    }
  }

  public addValidators<T>(
    formGroup: TypedFormGroup<T>,
    controlNames: (keyof T)[] | keyof T,
    validator: ValidatorFn | ValidatorFn[] | null
  ): void {
    if (Array.isArray(controlNames)) {
      for (const name of controlNames) {
        formGroup[name].setValidators(validator);
        formGroup[name].updateValueAndValidity();
      }
    } else {
      formGroup[controlNames].setValidators(validator);
      formGroup[controlNames].updateValueAndValidity();
    }
  }

  public setFilterAsQueryString(
    input: SearchListState,
    supportAppliedFilters: SearchAppliedFilter[]
  ) {
    const qsParams = JSON.stringify(supportAppliedFilters);

    this.router.navigate([], {
      queryParams: {
        filters: qsParams,
        search: input.search,
      },
      relativeTo: this.route,
      queryParamsHandling: 'merge',
    });
  }

  get weeklyCalendar() {
    return `${this.baseUrl}${URL_CONSTANTS.GET_WEEKLY_CALENDAR_V2}`;
  }

  get myAction() {
    return `${this.baseUrl}${URL_CONSTANTS.GET_MY_ACTION}`;
  }

  get myTeamAction() {
    return `${this.baseUrl}${URL_CONSTANTS.GET_MY_TEAM_ACTION}`;
  }

  // StopWatch
  get startStopWatch() {
    return `${this.baseUrl}${URL_CONSTANTS.START_STOPWATCH}`;
  }

  get stopStopWatch() {
    return `${this.baseUrl}${URL_CONSTANTS.STOP_STOPWATCH}`;
  }

  get timelogList() {
    return `${this.baseUrl}${URL_CONSTANTS.GET_TIME_LOG_LIST}`;
  }

  get timelogEntriesList() {
    return `${this.baseUrl}${URL_CONSTANTS.GET_TIME_LOG_ENTIRES_LIST}`;
  }

  get saveSearchDataToTimeLog() {
    return `${this.baseUrl}${URL_CONSTANTS.SAVE_SEARCH_DATA_TO_TIME_LOG_ENTIRES_LIST}`;
  }

  get deleteTimelogEntry() {
    return `${this.baseUrl}${URL_CONSTANTS.DELETE_TIME_LOG_ENTIRY}`;
  }

  // Actions List
  get changeRequestList() {
    return `${this.baseUrl}${URL_CONSTANTS.GET_CHANGE_REQUEST}`;
  }
  get maintenanceList() {
    return `${this.baseUrl}${URL_CONSTANTS.GET_MAINTENANCE_LIST}`;
  }
  get remoteRequestList() {
    return `${this.baseUrl}${URL_CONSTANTS.GET_REMOTE_HAND_REQUEST}`;
  }
  get attachmentDoc() {
    return `${this.baseUrl}${URL_CONSTANTS.ATTACHMENT_DOWNLOAD}`;
  }

  get mopDocUpload() {
    return `${this.baseUrl}${URL_CONSTANTS.UPLOAD_MOP}`;
  }
  get workOrderDocUpload() {
    return `${this.baseUrl}${URL_CONSTANTS.UPLOAD_WORK_ORDER_DOC}`;
  }
  get fetchPreventiveChangeNumber() {
    return `${this.baseUrl}${URL_CONSTANTS.GET_PREVENTIVE_CHANGE_NUMBER}`;
  }
  get correctiveWorkOrder() {
    return `${this.baseUrl}${URL_CONSTANTS.GET_CORRECTIVE_WORK_ORDER}`;
  }
  get correctiveUpload() {
    return `${this.baseUrl}${URL_CONSTANTS.UPLOAD_CORRECTIVE_DOC}`;
  }
  get updateCorrectiveMaintenance() {
    return `${this.baseUrl}${URL_CONSTANTS.UPDATE_CORRECTIVE_MAINTENANCE}`;
  }

  get fetchCorrectiveChangeNumber() {
    return `${this.baseUrl}${URL_CONSTANTS.GET_CORRECTIVE_CHANGE_NUMBER}`;
  }

  get correctiveWorkOrderDocUpload() {
    return `${this.baseUrl}${URL_CONSTANTS.UPLOAD_CORRECTIVE_WORK_ORDER_DOC}`;
  }

  public routeURLSubject: BehaviorSubject<any> = new BehaviorSubject<
    UserInfo | undefined
  >(undefined);
  public routeURL$ = this.routeURLSubject.asObservable();

  public routeRemoteHandURLSubject: BehaviorSubject<any> = new BehaviorSubject<
    UserInfo | undefined
  >(undefined);
  public routeRemoteHandURL$ = this.routeRemoteHandURLSubject.asObservable();

  public getAssets(searchQuery: string) {
    return this.http
      .get<any>(
        this.getApiUrl(URL_NAMES.GET_ASSETS) +
        '?query=' +
        encodeURIComponent(searchQuery)
      )
      .pipe(
        map((res: any) => ({
          data: res?.data.map(
            (response: any) =>
            ({
              name: response.name,
              code: response.sysId,
              ...response,
            } as Option)
          ),
        }))
      );
  }

  public getReportedByAndAssinee(buildingName: string) {
    let finalUrl = this.getApiUrl(URL_NAMES.GET_REPORTED_BY);
    if (buildingName != undefined && buildingName != null) {
      finalUrl =
        this.getApiUrl(URL_NAMES.GET_REPORTED_BY) + '?building=' + buildingName;
    }
    return this.http.get<any>(finalUrl).pipe(
      map((res: any[]) => ({
        data: res.map(
          (response) =>
          ({
            name: response.name,
            code: response.userName,
          } as Option)
        ),
      }))
    );
  }

  public submitCorrectiveMaintenance(
    maintenancePayload: ICorrectiveMaintenanceForm
  ): Observable<CorrectiveMaintenanceResponse> {
    return this.http.post<CorrectiveMaintenanceResponse>(
      this.getApiUrl(URL_NAMES.SUBMIT_CORRECTIVE_MAINTENANCE),
      maintenancePayload
    );
  }


  public addCorrectiveWorkOrderDocuments(sysId: string, payload: GenericType) {
    const url = this.getApiUrl(URL_NAMES.UPLOAD_CORRECTIVE_WORK_ORDER_DOC).replace('{{SYS_ID}}', sysId)
    return this.http.postCase(url, payload);
  }

  public formattedDateWithTimezoneOffset(date: Date) {
    const datePart = this.dateTimeService.transform(date, 'yyyy-MM-dd');
    const timeZoneOffset = this.getTimezoneOffset(date);

    return `${datePart}T00:00:00${timeZoneOffset}`;
  }

  public getTimezoneOffset(date: Date) {
    const offset = date.getTimezoneOffset();
    const absOffset = Math.abs(offset);
    const hour = this.padZero(Math.floor(absOffset / 60));
    const minutes = this.padZero(absOffset % 60);
    const sign = offset < 0 ? '+' : '-';
    return `${sign}${hour}:${minutes}`;
  }

  private padZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  public convertDateFormatForRequestV1(date?: Date): string {
    if (!date) return '';
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

    // Construct the desired format
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  }
}
