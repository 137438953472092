import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, map, shareReplay } from 'rxjs';
import {
  TimeLogEntries,
  StopWatch,
  DEFAULT_STOPWATCH_STATE,
  TimeLog,
  Option
} from '@qts-sdp-ui/shared-data';
import { HttpService } from '@qts-sdp-ui/shared-util';
import { QosCommonService } from './qos-common-service';

@Injectable({
  providedIn: 'root',
})
export class StopWatchService {
  public httpService = inject(HttpService);
  private qosUrlService = inject(QosCommonService);

  private stopWatchStateSubject: BehaviorSubject<StopWatch> =
    new BehaviorSubject<StopWatch>(DEFAULT_STOPWATCH_STATE);
  private stopWatchState$ = this.stopWatchStateSubject.asObservable();

  public updateFilterState(state: StopWatch) {
    this.stopWatchStateSubject.next(state);
  }

  public getStopWatchStateObservable() {
    return this.stopWatchState$;
  }

  // API calls
  public startStopWatch(payload: any) {
    return this.httpService
      .post<any>(
        `${this.qosUrlService.startStopWatch}`,
        payload
      )
      .pipe(shareReplay());
  }

  public stopStopWatch(payload: any) {
    return this.httpService
      .post<any>(
        `${this.qosUrlService.stopStopWatch}`,
        payload
      )
      .pipe(shareReplay());
  }

  public getTimeLogList(maintenanceDefinitionId: number, type:string) {
    return this.httpService
      .get<TimeLog>(`${this.qosUrlService.timelogList}?type=${type}&id=${maintenanceDefinitionId}`)
      .pipe(shareReplay());
  }

  public getTimeLogEntriesList() {
    return this.httpService.get<TimeLogEntries[]>(this.qosUrlService.timelogEntriesList).pipe(shareReplay());
  }

  public getTimeLogEntriesBySearch(searchByGroupUsers:boolean,searchQuery: string) {
    return this.httpService
      .get<any>(this.qosUrlService.timelogEntriesList + '?searchByGroupUsers=' +  searchByGroupUsers  + '&query=' + encodeURIComponent(searchQuery))
      .pipe(
        map((res: any) => ({
          data: res?.map(
            (response: any) =>
              ({
                name: response.name,
                code: response.id,
                ...response,
              } as Option)
          ),
        }))
      );
  }

  public saveSearchDataToTimeLogEntry(payLoadData: any) {
    return this.httpService
      .post<any>(
        `${this.qosUrlService.saveSearchDataToTimeLog}`,
        payLoadData
      )
      .pipe(shareReplay());
  }

  public deleteTimeLogEntry(type:string,id:number) {
    return this.httpService.delete<any>(this.qosUrlService.deleteTimelogEntry + type + '/' + id ).pipe(shareReplay());
  }

}
