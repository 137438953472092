export const casesListColumns = [
  {
    field: 'number',
    size: 'sm',
    label: 'Work Order',
    active: true,
    disabled: true,
  },
  {
    field: 'buildingName',
    size: 'sm',
    label: 'Building Name',
    sortable: true,
    active: true,
  },
  {
    field: 'deviceNames',
    size: 'md',
    label: 'Device Name',
    active: true,
  },
  {
    field: 'status',
    size: 'sm',
    label: 'State',
    active: true,
  },
  {
    field: 'assignedToName',
    size: 'sm',
    label: 'Assignee',
    sortable: true,
    active: true,
  },
];
