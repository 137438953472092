export enum URL_NAMES {
  GET_WORK_ORDER_DETAILS_VIEW = 'GET_WORK_ORDER_DETAILS_VIEW',
  GET_SERVICE_PROVIDER = 'GET_SERVICE_PROVIDER',
  UPDATE_MANAGE_MAINTENANCE = 'UPDATE_MANAGE_MAINTENANCE',
  GET_WORK_ORDER_DETAILS_BY_ID = 'GET_WORK_ORDER_DETAILS_BY_ID',
  GET_REPORTED_BY = 'GET_REPORTED_BY',
  SUBMIT_CORRECTIVE_MAINTENANCE = 'SUBMIT_CORRECTIVE_MAINTENANCE',
  GET_ASSETS = 'GET_ASSETS',
  UPLOAD_CORRECTIVE_WORK_ORDER_DOC = 'UPLOAD_CORRECTIVE_WORK_ORDER_DOC',
}

export const URL_CONSTANTS = {
  GET_WORK_ORDER_DETAILS_VIEW: '/api/v1/work-orders',
  GET_SERVICE_PROVIDER: '/api/v1/service-providers',
  GET_USER_CONTEXT: '/api/v1/user-context',
  GET_USER_CONTEXT_CAMUSES: '/api/v1/user-context/campuses',
  GET_USER_CONTEXT_BUILDING: '/api/v1/user-context/buildings',
  GET_ASSET_TYPES: '/api/v1/asset-types/maintenance-definition-asset-types',
  GET_MAINTENANCE_DEFINITIONS: '/api/v1/maintenance-definitions',
  UPDATE_MANAGE_MAINTENANCE: '/api/v1/work-orders/bulk-update',
  GET_WEEKLY_CALENDAR: '/api/v1/widgets/my-weekly-calendar',
  GET_WEEKLY_CALENDAR_V2: '/api/v2/widgets/my-weekly-calendar',
  GET_MY_ACTION: '/api/v1/work-orders/my-action/day-order-count',
  GET_MY_TEAM_ACTION: '/api/v1/work-orders/my-team-action/day-order-count',
  GET_WORK_ORDER_DETAILS_BY_ID: '/api/v1/work-orders/',

  GET_ASSIGNED_GROUP: '/api/v1/user-context/assigned-groups',
  GET_GROUP_USERS: '/api/v1/group-users',
  UPLOAD_MOP: '/api/v1/work-orders/preventive-order/upload-mops',
  UPLOAD_WORK_ORDER_DOC:
    '/api/v1/work-orders/preventive-order/upload-attachments',
  GET_PREVENTIVE_CHANGE_NUMBER: '/api/v1/change-requests/preventive-order/info',
  GET_CORRECTIVE_CHANGE_NUMBER: '/api/v1/change-requests/corrective-order/info',


  //StopWatch
  START_STOPWATCH: '/api/v2/stopwatch/start',
  STOP_STOPWATCH: '/api/v2/stopwatch/stop',
  GET_TIME_LOG_LIST: '/api/v2/stopwatch/time-log',
  GET_TIME_LOG_ENTIRES_LIST: '/api/v2/stopwatch/time-log-entries',
  SAVE_SEARCH_DATA_TO_TIME_LOG_ENTIRES_LIST: '/api/v2/stopwatch/add-to-list',
  DELETE_TIME_LOG_ENTIRY: '/api/v2/stopwatch/time-log-entries/',

  //Actions List
  GET_CHANGE_REQUEST: '/api/v1/change-requests',
  GET_MAINTENANCE_LIST: '/api/v1/work-orders/action-list-view/orders',
  GET_REMOTE_HAND_REQUEST: '/api/v1/remote-hands-tickets',
  GET_REMOTE_HAND_LIST_BY_ID: '/api/v1/remote-hands-tickets/',
  ATTACHMENT_DOWNLOAD: '/api/v1/attachment',
  UPDATE_REMOTE_HAND_LIST_BY_ID: '/api/v1/remote-hands-tickets/',
  GET_CHANGE_DETAILS_BY_ID: '/api/v1/change-requests/',

  // Corrective Maintenance
  GET_ASSETS: '/api/v1/assets',
  GET_REPORTED_BY: '/api/v1/group-users',
  SUBMIT_CORRECTIVE_MAINTENANCE: '/api/v1/work-orders',
  UPLOAD_CORRECTIVE_WORK_ORDER_DOC:
    '/api/v1/work-orders/corrective-order/{{SYS_ID}}/upload-attachments',

  //Dashboard
  GET_ACTION_SUMMARY: '/api/v1/widgets/action-summary',
  GET_MY_REMOTE_HANDS_TICKETS: '/api/v1/remote-hands-tickets/my-actions',
  GET_ESCORT: '/api/v1/escorts',
  UPDATE_ESCORT: '/api/v1/escorts/escort-cases/',
  UPDATE_ROUND: '/api/v1/rounds/',
  GET_ROUNDS: '/api/v1/rounds/dashboard-widget-rounds',

  // Manage Corrective Maintenance
  GET_CORRECTIVE_WORK_ORDER: '/api/v1/work-orders/actions/orders',
  UPLOAD_CORRECTIVE_DOC: '/api/v1/work-orders/corrective-order/',
  UPDATE_CORRECTIVE_MAINTENANCE: '/api/v1/work-orders/corrective-order/',
};
