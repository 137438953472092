import { Option } from '@qts-sdp-ui/shared-data';

export interface manageMaintenanceActionHistory {
  comment: string;
  author: string;
  action: string;
  operationDate: string;
  commentIsLong: boolean;
  commentForPanel: string;
}
export interface ITimeCardList {
  day: string;
  tracking: any;
}

export interface DayOrderCount {
  state: string;
  count: number;
}

export enum DAY_ORDER_STATE {
  WORK_IN_PROGRESS = 'Work in Progress',
  DRAFT = 'Draft',
  CLOSED = 'Closed',
}

export const FORMATTED_DAY_ORDER_STATE = {
  [DAY_ORDER_STATE.WORK_IN_PROGRESS]: 'In Progress',
  [DAY_ORDER_STATE.DRAFT]: 'Draft',
  [DAY_ORDER_STATE.CLOSED]: 'Closed Today',
};

// export interface StopWatch {
//   selectedTimeLog: TimeLogEntries | null;
// }

// export const DEFAULT_STOPWATCH_STATE = {
//   selectedTimeLog: null,
// };

// export interface TimeLogEntries {
//   id: number;
//   name: string;
//   assetType: string;
//   isStopwatchRunning: boolean;
//   totalTime?: number
//   stopwatchDto: Stopwatch;
// }
// export interface Stopwatch {
//   id: number;
//   totalTimeRecorded: number;
//   assignedToUsername: string;
//   maintenanceDefinitionId: number;
// }

export const columnDisplayName: { [key: string]: string } = {
  assignedTo: 'Assigned To',
  assignedGroups: 'Assignment Group',
  dateIntervalType: 'Date Interval',
  updatedDateFrom: 'Updated Date From',
  campuses: 'Campus',
  buildings: 'Building',
  statuses: 'State',
  updatedDateTo: 'Updated Date To',
  risk: 'Risk',
  changeTypes: 'Change Type',
  changeReasons: 'Change Reason',
  categories: 'Category',
  closeCodes: 'Close Code',
  expedited: 'Expedited',

  /// For Maintenance List
  deviceNames: 'Device Name', //company
  assignedGroup: 'assignedGroups',
  workLocation: 'Location',
  initialReason: 'Initial Reason',
  reportedBy: 'Reported By',
  workOrderType: 'Work Order Type',
  dateFrom: 'Date From',
  dateTo: 'Date To',
  parentNumber: 'Parent Work Order',
  priorities: 'Priority',
  serviceProviders: 'Service Provider',
  externalTechnician: 'External Technician',
  capital: 'Capital',

  // RemoteHands List
  types: 'RH Type',
  assignees: 'Assignee',
  createdDate: 'Created Date',
  updatedDate: 'Updated Date',
  closedDate: 'Closed Date',
  from: 'From',
  to: 'To',
  createdDateFrom: 'Created Date From',
  createdDateTo: 'Created Date To',
  closedDateFrom: 'Closed Date From',
  closedDateTo: 'Closed Date To',
};

export const expeditedOrder: Option[] = [
  { name: 'Yes', code: 'YES' },
  { name: 'No', code: 'NO' },
];

export enum CHANGE_REQUEST_CODE {
  ASSESS = 'ASSESS',
  AUTHORIZE = 'AUTHORIZE',
  CANCELED = 'CANCELED',
  CLOSED = 'CLOSED',
  COMPLETED = 'COMPLETED',
  IMPLEMENT = 'IMPLEMENT',
  NEW = 'NEW',
  REVIEW = 'REVIEW',
  SCHEDULED = 'SCHEDULED',
}

export const MAINTENANCE_STATE_CODE = {
  CLOSED_COMPLETE: 'CLOSED COMPLETE',
  CLOSED_CANCELLED: 'CLOSED CANCELLED',
  WORK_IN_PROGRESS: 'WORK IN PROGRESS',
  PENDING_REVIEW: 'PENDING REVIEW',
  ON_HOLD: 'ON HOLD',
  PLANNING: 'PLANNING',
  DRAFT: 'DRAFT',
};

export const WORK_ORDER_TYPE_CODE = {
  PREVENTATIVE_MAINTENANCE: 'PREVENTATIVE MAINTENANCE',
  CORRECTIVE_MAINTENANCE: 'CORRECTIVE MAINTENANCE',
};

export const RH_STATE_CODE = {
  OPEN: 'OPEN',
  CLOSED_COMPLETE: 'CLOSED COMPLETE',
  CLOSED_INCOMPLETE: 'CLOSED INCOMPLETE',
  WORK_IN_PROGRESS: 'WORK IN PROGRESS',
  CLOSED_SKIPPED: 'CLOSED SKIPPED',
  PENDING: 'PENDING',
};

export const changeState: Option[] = [
  { name: 'Assess', code: 'ASSESS' },
  { name: 'Authorize', code: 'AUTHORIZE' },
  { name: 'Canceled', code: 'CANCELED' },
  { name: 'Closed', code: 'CLOSED' },
  { name: 'Implement', code: 'IMPLEMENT' },
  { name: 'New', code: 'NEW' },
  { name: 'Review', code: 'REVIEW' },
  { name: 'Scheduled', code: 'SCHEDULED' },
];

export const changeReason: Option[] = [
  { name: 'Incident Resolution', code: 'Incident resolution' },
  { name: 'Problem Resolution', code: 'Problem resolution' },
  { name: 'Required By Customer', code: 'Required by Customer' },
  { name: 'Preventive Maintenance', code: 'Preventive Maintenance' },
  { name: 'Business Requirements', code: 'Business requirements' },
  { name: 'Network Requirements', code: 'Network requirements' },
  { name: 'Compliance Requirements', code: 'Compliance requirements' },
  { name: 'Product Or Service Changed', code: 'Product or service changed' },
  { name: 'Project Task', code: 'Project Task' },
];

export const closeCode: Option[] = [
  { name: 'Canceled', code: 'Canceled' },
  { name: 'Successful', code: 'Successful' },
  { name: 'Successful with issues', code: 'Successful with issues' },
  { name: 'Unsuccessful', code: 'Unsuccessful' },
];

export const categories: Option[] = [
  { name: 'Data Center Operations', code: 'Data Center Operations' },
  { name: 'DCIM', code: 'DCIM' },
  { name: 'Facilities / Electrical', code: 'Facilities / Electrical' },
  { name: 'Facilities / Maintenance', code: 'Facilities / Maintenance' },
  { name: 'Facilities / Mechanical', code: 'Facilities / Mechanical' },
  { name: 'FedRAMP', code: 'FedRAMP' },
  { name: 'General Construction', code: 'General Construction' },
  { name: 'Physical Security', code: 'Physical Security' },
  { name: 'Physical Security System', code: 'Physical Security System' },
];

export const changeType: Option[] = [
  { name: 'Emergency', code: 'EMERGENCY' },
  { name: 'Normal', code: 'NORMAL' },
  { name: 'Standard', code: 'STANDARD' },
];

export const QOS_AUTO_COMPLETE_FIELDS: string[] = ['assignedToName'];

export interface ICorrectiveMaintenanceForm {
  parentSysId?: string;
  deviceSysId: string;
  initiationReason: string;
  dueDate: string;
  reportedBy: string;
  name: string;
  description: string;
  priority: string;
  assignee: string;
  capital: boolean;
}

export enum ACTION_SUMMARY_TYPE {
  CORRECTIVE_MAINTENANCE = 'CORRECTIVE_MAINTENANCE',
  PREVENTIVE_MAINTENANCE = 'PREVENTIVE_MAINTENANCE',
  REMOTE_HANDS = 'REMOTE_HANDS',
}

export interface ActionSummaryResponse {
  type: ACTION_SUMMARY_TYPE;
  myAction: MyAction;
  teamAction: MyAction;
}

export interface FormattedActionSummary {
  myAction: number;
  teamAction: number;
}

export interface ActionSummary {
  [key: string]: FormattedActionSummary;
}

export interface MyAction {
  draft: number;
  inProgress: number;
  closed: number;
  total: number;
}

export const DEFAULT_ACTION_SUMMARY = {
  myAction: 0,
  teamAction: 0,
};

export enum ACTION_SUMMARY_ACTION_TYPE {
  PREVENTIVE_MY_ACTION = 'PREVENTIVE_MY_ACTION',
  PREVENTIVE_TEAM_ACTION = 'PREVENTIVE_TEAM_ACTION',

  CORRECTIVE_MY_ACTION = 'CORRECTIVE_MY_ACTION',
  CORRECTIVE_TEAM_ACTION = 'CORRECTIVE_TEAM_ACTION',

  REMOTE_HANDS_MY_ACTION = 'REMOTE_HANDS_MY_ACTION',
  REMOTE_HANDS_TEAM_ACTION = 'REMOTE_HANDS_TEAM_ACTION',
}

export interface Escort {
  id: number;
  caseNumber: string;
  campus: string;
  building: string;
  companyName: string;
  start: string;
  end: string;
  companyCpy?: string
  escortType?: string;
  startTime?: string;
  endTime?: string;
  name?: string;
}

//#region Detail View
export enum SupportCaseType {
  REMOTE_HANDS = 'REMOTE_HANDS',
}

//#region Detail View
export enum FormDetailsViewColumnKey {
  companyName = 'companyName',
  approval = 'approval',
  priority = 'priority',
  state = 'state',
  assignee = 'assignee',
  rhType = 'rhType',
  shortDescription = 'shortDescription',
  location = 'campusName',
  buildingName = 'buildingName',
  spaceOrRack = 'spaceOrRack',
  description = 'description',
}
export interface RoundResponse {
  id: number;
  sysId: string;
  number: string;
  state: string;
  scheduledStartTime: string;
  roundTime: string;
  campusName: string;
  buildingName: string;
  assignedToUsername: string;
  assignedToName: string;
}

export interface Round {
  key: string;
  values: RoundResponse[];
}

export const ASSIGNED_TO_ME_VALID_STATUS = ['Draft', 'Work in Progress'];

export const hhmmReg = new RegExp(/^([0-6][0-9]|70):[0-5][0-9]$/);
