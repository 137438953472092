import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Subscription, finalize } from 'rxjs';
import { UserData, UserInfo } from '@qts-sdp-ui/shared-data';
import { QosCommonService } from './qos-common-service';

@Injectable({
  providedIn: 'root',
})
export class QosUserService {
  private subscriptionHolder: Subscription = new Subscription();
  private qosCommonService = inject(QosCommonService);

  private userContextSubject: BehaviorSubject<UserInfo | undefined> =
    new BehaviorSubject<UserInfo | undefined>(undefined);
  public userContext$ = this.userContextSubject.asObservable();

  public userData: UserData | null = null;
  public isLoading = true;

  public loadUserData() {
    this.subscriptionHolder.add(
      this.qosCommonService
        .getUserContextQos()
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe((userData) => {
          this.userContextSubject.next(userData);
        })
    );
  }
}
