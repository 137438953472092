
  export interface ISUBMITMAINTENANCE {
    sysIds: string[]
    workOrderData?: WorkOrderData
    timeCardData?: TimeCardData
    workOrderType?: string
  }
  
  export interface WorkOrderData {
    state: string
    resolutionCode: string
    assignToMe: boolean
    comment: string
    serviceProviderSysId: string
    externalTechnician: string
    laborAdded: string
    resolutionDetail: string
  }
  
  export interface TimeCardData {
    weekStartsOn: string
    monday: number
    tuesday: number
    wednesday: number
    thursday: number
    friday: number
    saturday: number
    sunday: number
  }
  
  // export interface WorkOrderData {
  //   state: string;
  //   resolutionCode: string;
  //   assignToMe: boolean;
  //   comment: string;
  //   serviceProviderSysId: string;
  //   externalTechnician: string;
  // }
  
  // export interface TimeCardData {
  //   weekStartsOn: string;
  //   monday: number;
  //   tuesday: number;
  //   wednesday: number;
  //   thursday: number;
  //   friday: number;
  //   saturday: number;
  //   sunday: number;
  // }
  