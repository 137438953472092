export interface AppSettings {
  QOSRestUrl: string;
  QOSPendoKey: string;
  clientId: string;
  issuer: string;
  authCallback: string;
}

let appConfig: AppSettings;

export const setSettings = (data: AppSettings) => {
  appConfig = { ...data };
};

export const getSettings = (): AppSettings => {
  return appConfig;
};
