import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { AppSettings, setSettings } from '@qts-qos-ui/qos-app-data';

async function initialize() {
  const appModuleContainer = await import('./app/app.config');
  bootstrapApplication(AppComponent, appModuleContainer.appConfig).catch(
    (err) => console.error(err)
  );
}

fetch('/assets/config.json') // URL to your remote config
  .then((response) => {
    if (response.ok) {
      return response.text(); // make it response.json() if your response is JSON directly.
    }
    throw new Error('Not Found');
  })
  .then(async (response) => {
    const config = JSON.parse(response) as AppSettings;
    setSettings(config);
  })
  .catch((err) => console.log('error', err))
  .finally(initialize);
